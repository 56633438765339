import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      /* TODO: This radius should be added in the core, and pulled from the radius defined in the theme */
      .leshen-columns-card > div {
        border-radius: 8px;
      }

      .leshen-table-wrapper {
        max-height: 450px;
        overflow-y: scroll;
      }

      .show-desktop,
      .show-mobile {
        display: none;
      }

      .leshen-package-card {
        isolation: unset !important;
      }

      .package-label {
        z-index: -1 !important;
      }

      .leshen-phone-cta,
      .leshen-link-button {
        box-shadow: none !important;
      }

      @media (min-width: 768px) {
        .show-desktop {
          display: block;
        }
      }

      @media (max-width: 768px) {
        .show-mobile {
          display: block;
        }
      }

      .Twilio-EntryPoint {
        position: absolute !important;
        bottom: 73px !important;

        @media screen and (min-width: 769px) {
          bottom: 89px !important;
        }
      }
    `}
  />
))

export default GlobalCss
