import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const PriceStyles = withTheme(() => (
  <Global
    styles={css`
      .duration-html,
      .leshen-price-duration {
        font-family: 'PF Din Text Medium', Helvetica !important;
      }

      .leshen-hero {
        &.background-color-primary {
          .leshen-price-change * {
            color: white !important;
          }
        }
      }
    `}
  />
))

export default PriceStyles
